import {useState, useEffect} from 'react';
import {Row, Col, Button, DatePicker, message, Select, Space, Result, Modal} from 'antd';
import {useStepUpdateContext} from '../../contexts/step_context';
import {useAnswersUpdateContext} from '../../contexts/answers_context';
import noImage from '../../images/no_1.svg'
import yesImage from '../../images/yes.svg'
import bothImage from '../../images/both.svg'
import BackButton from '../common/back_button';

import naf_logo from '../../images/NAF_logo.png'
import omaha_logo from '../../images/omaha.png'
import mortgage_logo from '../../images/mortgage.png'
import hla_logo from "../../images/hla.png";

import './styles.css';
import { Typography } from 'antd';
import moment from 'moment'


const year = moment(new Date("01-01-2010"), "MM-DD-YYYY");
const { Title, Text } = Typography;
const {Option} = Select;

function YearOfBirth() {
    const stepUpdate = useStepUpdateContext();
    const answersUpdate = useAnswersUpdateContext();
    const question = 'year_of_birth'

    const [inputValue, setInputValue] = useState('');
    const [date, setDate] = useState('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');

    const handleNextPageClick = () => {
        console.log(date, month, year)
        answersUpdate(question, month.toString() + '-' + date.toString() + '-' + year.toString())
        setTimeout(() => {
            window.location.href = "http://thankyouhomeowner.com/"
        }, 2000)

    }


    function success() {
        if (!date || !year || !month) {
            message.error('Please select your date of birth!')
            return
        }
        Modal.success({
            content: <Result
                status="success"
                title="Thank You"
                subTitle={<>
                <p> We have matched you with this lender. Prepare to be called to discuss rate and loan scenarios. </p>
                <br/>
                <Row>
                    <Col span={24}>
                        <img src={naf_logo} height='30' width='160' alt='' style={{margin: 7}}/>
                    </Col>
                </Row>
                {/* <Row>
                    <Col span={24}>
                        <img src={omaha_logo} height='80' width='100' alt='' style={{margin: 7}}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <img src={mortgage_logo} height='40' width='140' alt='' style={{margin: 7}}/>
                    </Col>
                </Row>
                <Row>
                <Col span={24}>
                    <img src={hla_logo} height='40' width='160' alt='' style={{margin: 7}}/>
                </Col>
                </Row> */}
                    </>}
            />,
            onOk: handleNextPageClick
        });
    }

    return (
        <>
            <Row style={{paddingBottom: 30}}>
                <Col span={24} style={{ textAlign: 'center', verticalAlign: 'center' }}>
                    <BackButton />
                </Col>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Title>Please select your birthdate</Title>
                    <Text>Last Question. Click Next For Results</Text>
                </Col>
            </Row>
            <Row justify="center" gutter={16} style={{paddingBottom: 30}}>
                <Col span={4}>
                    <Row justify="center">
                        <Space>
                            <Col>
                                <Select defaultValue="month" style={{width: 100}} onChange={e => setMonth(e)}>
                                    {
                                        [...Array(12).keys()].map((month, index) => (
                                            <Option value={month + 1}>{month + 1}</Option>
                                        ))
                                    }
                                </Select>
                            </Col>

                            <Col>

                                <Select defaultValue="date" style={{width: 100}} onChange={e => setDate(e)}>
                                    {
                                        [...Array(31).keys()].map((date, index) => (
                                            <Option value={date + 1}>{date + 1}</Option>
                                        ))

                                    }
                                </Select>


                            </Col>

                            <Col>
                                <Select defaultValue="year" style={{width: 100}} onChange={e => setYear(e)}>
                                    {
                                        [...Array(100).keys()].map((year, index) => (
                                            <Option value={2022 - year}>{2022 - year}</Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                        </Space>
                    </Row>
                </Col>
            </Row>
            <Row justify="center" style={{paddingBottom: 30}}>
                <Col span={24} style={{textAlign: 'center'}}>
                    <Button type="primary" onClick={success}>Next</Button>
                </Col>
                <Col xs={20} xl={4} style={{textAlign: 'center', color: 'gray'}}>
                    <br/>
                    <p>By clicking the button above, you agree to be contacted by us, at the address entered previously
                        for promotional emails and consent, electronically via E-sign, to the our Terms of Use, Privacy
                        Policy, and Consent to Doing Business Electronically.</p>
                </Col>
            </Row>
        </>
    );
}

export default YearOfBirth;
