import { Layout, Row, Col, Image } from 'antd';
import logo from '../images/logo.png'
import lock_image from '../images/lock.svg'
import footer from '../images/footer.svg'
import Terms_And_Conditions from "../components/common/terms_and_conditions";

import './styles.css'
const { Header, Content, Footer } = Layout;

function CustomLayout({ children }) {
    return (

        <Layout className="layout" style={{ minHeight: "100vh"}}>
            <Header style={{ backgroundColor: 'white', height: 80 }}>
                <Row style={{ paddingTop: 10 }}>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <img src={logo} height='35' width='130' alt='' />
                    </Col>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <img src={lock_image} height='25' width='25' alt='' />
                        Secure Form
                    </Col>
                </Row>
            </Header>
            <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                <Col span={24} style={{ textAlign: 'center', color: 'gray' }}>
                    Won't Affect Credit. 100% FREE. Takes 2 Minutes to Start.
                </Col>
            </Row>

            <Content style={{ paddingTop: 30 }}>
                <div>
                {children}
                </div>
            </Content>
            {/* <Content style={{ paddingTop: 30 }}>
            <div className='background-img-footer'/>
            </Content> */}
            <Footer style={{ textAlign: 'center'}}>

            </Footer>
            <Terms_And_Conditions />
        </Layout>


    );
}

export default CustomLayout;
